import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { concatMap, delay, map, mergeMap, retryWhen, take } from 'rxjs/operators'
import { Router } from '@angular/router'
import { SocialAuthService } from 'angularx-social-login'
import { environment } from '@environment/environment'
import { ApiGeeService } from '@app/services/api-gee.service'

@Injectable()
export class GoogleInterceptor implements HttpInterceptor {
	token: string
	apiGeeToken: string

	constructor(private router: Router, private authService: SocialAuthService, private apiGeeService: ApiGeeService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (environment.interceptor_whitelist.some(x => request.url.includes(x))) return next.handle(request)

		return this.apiGeeService.token$.pipe(
			map(token => {
				let headers = {
					setHeaders: {
						Authorization: `Bearer ${token}`,
						key: environment.apiKey,
						apiKey: environment.apiGeeKey,
						apiKeySecret: environment.apiGeeSecret,
					} as any,
				}

				if (request.url.includes(environment.workfrontApiUrl)) {
					headers.setHeaders.id_token = this.apiGeeService.idToken
				}
				// console.log('[REQUEST]', request)
				return request.clone(headers)
			}),
			concatMap(authReq => next.handle(authReq)),
			map((resp: any) => {
				// handle errors with http 200
				// console.log('[RESPONSE]', request.url)
				if (
					resp.body?.status === 'failed' &&
					/* hack for status failed when trying to get metadata file*/ !request.url.includes(environment.fileGetInfoUrl)
				) {
					throw new HttpErrorResponse({ status: 401 })
				}
				return resp
			}),
			retryWhen((errors: Observable<any>) =>
				errors.pipe(
					mergeMap((error, index) => {
						// console.log('[ERROR] retryWhen index: ' + index, error)
						// any other error than 401 with {error: 'invalid_grant'} should be ignored by this retryWhen
						if (error.status !== 401 && error.status !== 504 && error.status !== 500) {
							return throwError(error)
						}

						if (index < 2) {
							return this.apiGeeService.refreshToken$
						}

						this.authService.signOut()
						this.router.navigate(['/login'])
						return throwError(error)
					}),
					delay(10000),
					take(5),
					// first request should refresh token and retry,
					// if there's still an error the second time is the last time and should navigate to login
				),
			),
		)
	}
}
