// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// declare const require: any

export const environment = {
	production: true,
	version: require('./../../package.json').version,
	googleClientId: '664638597808-bcvlep8iolh4hihf7gn9u0j6jumvja7n.apps.googleusercontent.com',
	oldApiBaseUrl: 'https://api-prod.us.pwc.com/dms/sdm/v1/',
	// uploadBaseUrl: 'https://api-prod.us.pwc.com/uploadservice/sdm/v1/',
	// searchApiBaseUrl: 'https://api-prod.us.pwc.com/searchservice/sdm/v1/',
	// downloadFolderApiBaseUrl: 'https://api-prod.us.pwc.com/folderdownloadservice/sdmtasks/v1/sdm/',
	apiBaseUrl: 'https://api-prod.us.pwc.com/dms/qs/filefolder/sdm/v1/',
	repositoryApiBaseUrl: 'https://api-prod.us.pwc.com/dms/qs/repo/sdm/v1/',
	uploadBaseUrl: 'https://api-prod.us.pwc.com/dms/qs/upload/sdm/v1/',
	searchApiBaseUrl: 'https://api-prod.us.pwc.com/dms/qs/search/sdm/v1/',
	downloadApiBaseUrl: 'https://api-prod.us.pwc.com/dms/qs/download/sdm/v1/',
	downloadFolderApiBaseUrl: 'https://api-prod.us.pwc.com/folderdownloadservice/sdmtasks/v1/sdm/',
	bucketName: 'dam-d-app-file-upload-n',
	apiKey: 'AIzaSyCWVg5dhuKzDGoh2wFPcrrYrqYFVOeMYCk',
	apiGeeAuthentication: 'https://api-prod.us.pwc.com/dms/qs/auth/sdm/v1/user/token/get?user_email=',
	apiGeeKey: '3eTJwjlbK9jAvKNYxPhwNB33pQmkxfDD',
	apiGeeSecret: 'oGOrvtiBKHiQ7C0f',
	fileUploadBucket: 'dam-d-app-file-upload-n',
	fileVersionate: 'file/versions/create',
	fileVersions: 'file/versions/list',
	fileUploadStep1: 'file/gcs/generate',
	fileUploadStep3: 'file/upload',
	fileLinkApiUrl: 'file/link/get',
	fileGetUrl: 'file/get',
	fileGetInfoUrl: 'file/info/get',
	fileUpdateApiUrl: 'file/info/update',
	fileMoveApiUrl: 'folder/files/move',
	fileMetadataUpdateApiUrl: 'file/metadata/update',
	fileAuditHistory: 'file/audithistory/get',
	fileDeleteApiUrl: 'folder/files/multi/delete',
	fileDictUrl: 'file/dict',
	folderApiUrl: 'folder/list',
	folderTreeApiUrl: 'tree/list',
	folderHierarchyApiUrl: 'folder/hierarchy',
	folderCreateApiUrl: 'folder/create',
	folderUpdateApi: 'folder/update',
	folderDeleteApiUrl: 'folder/delete',
	repositoryGetMetadata: 'repository/metadata/get',
	repositoryGet: 'repository/get',
	repositoryUpdate: 'repository/update',
	userLookup: 'lookup/get',
	searchAllList: 'search/all/list',
	searchFolders: 'search/all/folder',
	userPermissions: 'access/update',
	downloadFolderUrl: 'folder/download',
	downloadFolderStatusUrl: 'folder/download/status',
	projectRepositoryId: 'ahRzfnBnLXVzLXAtYXBwLTM0OTc2M3IaCxINU0RNUmVwb3NpdG9yeRiAgIDSltX8CQw',
	guideRepositoryId: 'ahRzfnBnLXVzLXAtYXBwLTM0OTc2M3IaCxINU0RNUmVwb3NpdG9yeRiAgICSndS4Cww',
	libraryRepositoryId: 'ahRzfnBnLXVzLXAtYXBwLTM0OTc2M3IaCxINU0RNUmVwb3NpdG9yeRiAgICys-K5CQw',
	repoRootName: 'CDAM-Projects',

	metadataFilePath: '/assets/',
	metadataFileName: 'dam.metadata.txt',
	metadataProjectUserFieldName: 'Project users',
	metadataToRemoveInFiles: ['Project users', 'Status'],
	metadataHidden: ['Child project'],
	metadata: {
		referenceNumber: {
			is_required: false,
			metadata_id: '',
			name: 'Reference number',
			type: 'Text',
			value: '',
		},
		workfrontId: {
			is_required: false,
			metadata_id: '',
			name: 'Workfront Id',
			type: 'Text',
			value: '',
		},
		entryDate: {
			is_required: false,
			metadata_id: '',
			name: 'Entry date',
			type: 'Text',
			value: '',
		},
		deadline: {
			is_required: false,
			metadata_id: '',
			name: 'Deadline',
			type: 'Text',
			value: '',
		},
		los: {
			is_required: false,
			metadata_id: '',
			name: 'LoS',
			type: 'Text',
			value: '',
		},
		businessUnit: {
			is_required: false,
			metadata_id: '',
			name: 'Business Unit/Platform',
			type: 'Text',
			value: '',
		},
		sponsorName: {
			is_required: false,
			metadata_id: '',
			name: 'Sponsor name',
			type: 'Text',
			value: '',
		},
		projectUsers: {
			is_required: false,
			metadata_id: '',
			name: 'Project users',
			type: 'Text',
			value: '',
		},
		homeGroup: {
			is_required: false,
			metadata_id: '',
			name: 'Home group',
			type: 'Text',
			value: '',
		},
		status: {
			is_required: false,
			metadata_id: '',
			name: 'Status',
			type: 'Text',
			value: '',
		},
		childProject: {
			is_required: false,
			metadata_id: '',
			name: 'Child project',
			type: 'Text',
			value: '',
		},
		parentProject: {
			is_required: false,
			metadata_id: '',
			name: 'Parent project',
			type: 'Text',
			value: '',
		},
	},

	folderTemplateProject: [
		// {
		//   name: 'Old',
		//   description: 'Old/Archive files'
		// },
		// {
		//   name: 'WIP',
		//   description: 'Work in Progress'
		// },
		// {
		//   name: 'Final',
		//   description: 'Final Deliverables'
		// }
	],
	defaultTimeOutRequest: 1000,

	workfrontApiUrl: 'https://workfront-api.azurewebsites.net/api/',
	workfrontApiGetStorageProjects: 'storage',
	workfrontApiCreateStorageProjects: 'storage/create',
	// workfrontUrl:'https://reverse-proxy-gb.azurewebsites.net/',
	workfrontStatusFilter: ['CUR', 'REV'],
	interceptor_whitelist: ['https://api-prod.us.pwc.com/dms/qs/auth/sdm/v1/user/token/get'],
	workfrontSiteUrl: 'https://pwcmktg.my.workfront.com',
	// GATrackingID: 'UA-125716278-1',
	GACategories: {
		global: 'Global',
		asset: 'Assets',
		filters: 'Filters',
		workfront: 'Workfront',
	},

	los: [
		{ key: 'Advisory', displayName: 'Advisory' },
		{ key: 'Audit/Assurance', displayName: 'Audit/Assurance' },
		{ key: 'IFS', displayName: 'IFS' },
		{ key: 'Risk Assurance', displayName: 'Risk Assurance' },
		{ key: 'Tax', displayName: 'Tax' },
	],
	businessUnit: [
		{ key: 'Alliances', displayName: 'Alliances' },
		{ key: 'Brexit', displayName: 'Brexit' },
		{ key: 'CFODirect', displayName: 'CFODirect' },
		{ key: 'Diversity', displayName: 'Diversity' },
		{ key: 'Ethics & Compliance', displayName: 'Ethics & Compliance' },
		{ key: 'Financial Services (all)', displayName: 'Financial Services' },
		{ key: 'Firm leadership', displayName: 'Firm leadership' },
		{ key: 'Geography Markets', displayName: 'Geography Markets' },
		{ key: 'Global Mobility', displayName: 'Global Mobility' },
		{ key: 'Governance Insights Center', displayName: 'Governance Insights Center' },
		{ key: 'Human Capital/Sourcing/Alumni/L&D/FTN', displayName: 'Human Capital/Sourcing/Alumni/L&D/FTN' },
		{ key: 'Integrated Content', displayName: 'Integrated Content' },
		{ key: 'IT', displayName: 'IT' },
		{ key: 'Marketing & Comms', displayName: 'Marketing & Comms' },
		{ key: 'New Ventures', displayName: 'New Ventures' },
		{ key: 'PCS', displayName: 'PCS' },
		{ key: 'People & Organization/HRS/Saratoga', displayName: 'People & Organization/HRS/Saratoga' },
		{ key: 'Platforms- Deals', displayName: 'Platforms- Deals' },
		{ key: 'Platforms- Digital', displayName: 'Platforms- Digital' },
		{ key: 'Platforms- Fit for Growth', displayName: 'Platforms- Fit for Growth' },
		{ key: 'Platforms- Risk & Regulatory', displayName: 'Platforms- Risk & Regulatory' },
		{ key: 'Platforms- Tax Managed Services', displayName: 'Platforms- Tax Managed Services' },
		{ key: 'Platforms- Workforce of the Future', displayName: 'Platforms- Workforce of the Future' },
		{ key: 'Professional Development Program (PDP)', displayName: 'Professional Development Program (PDP)' },
		{ key: 'Premiere Events (The Exchange, etc)', displayName: 'Premiere Events (The Exchange, etc)' },
		{ key: 'Pursuits', displayName: 'Pursuits' },
		{ key: 'Responsible Business Leadership', displayName: 'Responsible Business Leadership' },
		{ key: 'Sectors', displayName: 'Sectors' },
		{ key: 'Strategy&', displayName: 'Strategy&' },
		{ key: 'Other', displayName: 'Other' },
	],

	workfrontStatus: {
		CUR: 'Current',
		REV: 'Ready for review',
		CLS: 'Closed',
		CPL: 'Complete',
		DED: 'Canceled',
		OHN: 'On Hold',
		PLN: 'Planning',
	},
	workfrontActiveStatus: ['CUR'],

	apmServer: 'https://70fc75b62a9d432fa95251f825b3afe3.apm.us-central1.gcp.cloud.es.io:443',
	apmServiceName: 'DAM',
}
